<template>
  <b-container fluid >
    <b-row>
      <b-col md="12" class="heading_alignment">
        <iq-card style="padding-left:20px; padding-right:20px">
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{cvCardTitle}}
            </h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="clubAdd">
              {{cvbtnAddNew}}
            </b-button>
          </template>
          <template>
            <b-row class="mb-3">
              <b-col md="4">
                <b-form-input
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>
              <!-- <b-col md="8">
                <b-button size="sm" variant="outline-secondary" class="pull-right mr-2">Excel</b-button>
                <b-button size="sm" variant="outline-secondary" class="pull-right mr-2">Csv</b-button>
                <b-button size="sm" variant="outline-secondary" class="pull-right mr-2">Coustomize</b-button>
              </b-col> -->
            </b-row>
            <b-row>
              <b-col md="12" v-if="clubObjList && clubObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="clubObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage">
                  <template v-slot:cell(created_on)="data">
                    {{getFormattedDateTime(data.item.created_on)}}
                  </template>
                  <template v-slot:cell(deleted)="data">
                    <b-badge v-if="data.item.deleted === 0" class="pointer" variant="success mr-1 mb-1" size="sm" @click="openUrlInNew(data.item.event_weblink)">
                      Published
                    </b-badge>
                    <b-badge v-if="data.item.deleted === 2" class="pointer" variant="warning mr-1 mb-1" size="sm" @click="openUrlInNew(data.item.event_weblink)">
                      Unpublished
                    </b-badge>
                    <b-badge v-if="data.item.deleted === 1" class="pointer" variant="primary mr-1 mb-1" size="sm" @click="openUrlInNew(data.item.event_weblink)">
                      Deleted
                    </b-badge>
                    <br/>
                    <b-button variant="light mr-1 mb-1" :disabled="data.item.deleted === 2" size="sm" @click="clubPublishUnpublish(data.item)" v-if="!data.item.editable">
                      <i class="fa fa-toggle-off m-0" title="Unpublish" style="color:red"></i>
                    </b-button>
                    <b-button variant="light mr-1 mb-1" :disabled="data.item.deleted === 0" size="sm" @click="clubPublishUnpublish(data.item)" v-if="!data.item.editable">
                      <i class="fa fa-toggle-on m-0" title="Publish" style="color:green"></i>
                    </b-button>
                  </template>

                  <template v-slot:cell(action)="data">
                    <b-button variant="light mr-1 mb-1" size="sm" @click="clubEdit('MODAL', data.item)" v-if="!data.item.editable">
                      <i class="ri-ball-pen-fill m-0"></i>
                    </b-button>
                    <b-button variant="light" @click="showClubDeleteDialog(data.item)" size="sm">
                      <i class="ri-delete-bin-line m-0"></i>
                    </b-button>
                  </template>
                </b-table>
              </b-col>
              <b-col md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1">
              </b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModelClubAdd"
      scrollable
      :title="cvAddModalHeader"
      size="xl">
      <ClubAdd :propOpenedInModal="true" @emitCloseClubAddModal="closeClubAddModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="clubAdd('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeClubAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelClubEdit"
      scrollable
      :title="cvEditModalHeader"
      size="xl">
      <ClubEdit :propOpenedInModal="true" :propClubObj="clubEditObj" @emitCloseClubEditModal="closeClubEditModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="clubEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeClubEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelClubDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>
        {{cvDelMessage}}
      </p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelClubDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="clubDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>
<script>
import { Clubs } from "../../../FackApi/api/club.js"
import ClubAdd from "./ClubAdd"
import ClubEdit from "./ClubEdit"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse.js"

export default {
  name: "ClubList",
  components: {
    ClubAdd,
    ClubEdit
  },
  data () {
    return {
      cvCardTitle: "Clubs",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit Club",
      cvAddModalHeader: "Add Club",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Club List Response",
      showModelClubAdd: false,
      showModelClubEdit: false,
      showModelClubDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left", sortable: true },
        { label: "Status ", key: "deleted", class: "text-left align-text-top", sortable: true },
        { label: "Name", key: "club_name", class: "text-left", sortable: true },
        { label: "About", key: "club_desc", class: "text-left", sortable: true },
        { label: "Follower count", key: "follower_count", class: "text-left", sortable: true },
        { label: "Gide Plus ", key: "is_gide_plus", class: "text-left", sortable: false },
        { label: "Created On ", key: "created_on", class: "text-left", sortable: true },
        { label: "Action ", key: "action", class: "text-left", sortable: false }
      ],
      clubObjList: null,
      clubEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      delObj: null
    }
  },
  mounted () {
    this.clubListAdmin()
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  methods: {
    /**
     * getFormattedDateTime
     */
    getFormattedDateTime (datetime) {
      try {
        return moment(datetime).format("DD-MM-YYYY hh:mm:ss")
      }
      catch (err) {
        console.error("Exception occurred at getFormattedDateTime() and Exception:", err.message)
      }
    },
    /**
     * clubList
     */
    async clubListAdmin () {
      try {
        let clubListResp = await Clubs.clubListAdmin(this)
        if (clubListResp.resp_status) {
          this.clubObjList = Object.values(clubListResp.resp_data.data)
          this.totalRows = clubListResp.resp_data.count
        }
        else {
          this.toastMsg = clubListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at clubListAdmin() and Exception:", err.message)
      }
    },
    /**
     * clubAdd
     */
    clubAdd (type) {
      try {
        this.$router.push(`/club_create/${this.userData.user_id}`)
      }
      catch (err) {
        console.error("Exception occurred at clubAdd() and Exception:", err.message)
      }
    },
    /**
     * clubEdit
     */
    clubEdit (type, item) {
      try {
        this.clubEditObj = item
        this.$router.push("/club_edit/" + this.clubEditObj.club_id)
      }
      catch (err) {
        console.error("Exception occurred at clubEdit() and Exception:", err.message)
      }
    },
    /**
     * showClubDeleteDialog
     */
    showClubDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelClubDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showClubDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * clubDelete
     */
    async clubDelete () {
      try {
        let clubDelResp = await Clubs.clubDelete(this, this.delObj.club_id)
        await ApiResponse.responseMessageDisplay(this, clubDelResp)
        if (clubDelResp && !clubDelResp) {
          this.showModelClubDelete = false
          return false
        }
        let index = this.clubObjList.indexOf(this.delObj)
        this.clubObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelClubDelete = false
      }
      catch (err) {
        console.error("Exception occurred at clubDelete() and Exception:", err.message)
      }
    },
    /**
     * closeClubAddModal
     */
    closeClubAddModal (clubAddData) {
      try {
        if (clubAddData) {
          if (this.clubObjList && this.clubObjList.length >= 1) {
            let clubObjLength = this.clubObjList.length
            let lastId = this.clubObjList[clubObjLength - 1]["id"]
            clubAddData.id = lastId + 1
          }
          else {
            this.clubObjList = []
            clubAddData.id = 11111
          }
          // clubAddData.club_id = `CLUB${clubAddData.id}`
          clubAddData.created_on = moment()
          this.clubObjList.unshift(clubAddData)
          this.totalRows = this.totalRows + 1
        }
        this.showModelClubAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closeclubAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeClubEditModal
     */
    closeClubEditModal () {
      try {
        this.showModelClubEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeClubEditModal() and Exception:", err.message)
      }
    },
    /**
     * Publish/UnPublish club
     */
    async clubPublishUnpublish (club) {
      let payload = {
        club_id: club.club_id,
        deleted: null
      }

      if (club.deleted === 0) {
        // club is published then unpublish
        payload.deleted = 2
      }
      else if (club.deleted === 2) {
        payload.deleted = 0
      }
      else {
        return
      }

      let clubResp = await Clubs.club_publish_unpublish(this, payload)
      if (clubResp.resp_status === true) {
        club.deleted = payload.deleted
      }
      else {
        ApiResponse.responseMessageDisplay(this, clubResp)
      }
    }
  }
}
</script>
